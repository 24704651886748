body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
}

.App-logo {
  max-width: 150px;
}

.App-logo-white {
  display: flex;
  margin: 10px auto 30px auto;
  max-width: 150px;
}